<template>
  <div class="page-info-content">
    <DetailsPage
      :details-item-arr="detailsItemArr"
      :detail-obj="detailObj"
      details-title="采购合同详情"
    />
    <el-form
      ref="form"
      :model="ruleForm"
      class="page-form form-item-row"
      label-position="left"
      label-width="150px"
    >
      <!-- 表格 -->
      <div class="purchase-goods">
        <div class="page-subtitle-shade">
          采购货物明细<span
            class="tip"
          >提示：代采天数不满七天按七天计息,如最后一次提货，货款将扣除保证金金额。</span>
        </div>
        <Table
          class=""
          :item-data="itemData"
          :list-data="ruleForm.goodsReqList"
        >
          <!-- <template #avlbAmount="{ row }">
            <el-input v-model="row.avlbAmount" type="number" placeholder="请输入" @input="enterNumberAmount(row)" />
          </template> -->
          <template #avlbWeight="{ row }">
            <el-input
              v-model="row.avlbWeight"
              type="number"
              placeholder="请输入"
              @change="enterNumberWeight(row)"
            />
          </template>
        </Table>
      </div>
      <!-- 提货单信息 -->
      <div class="page-subtitle-shade">
        提货单信息
      </div>
      <div class="form-item-row">
        <!-- <el-form-item label="本次提货总数量：">
          <el-input v-model="ruleForm.totalQuantity" placeholder="输入货物明细自动计算" disabled />
        </el-form-item> -->
        <el-form-item label="本次提货总数量(吨)">
          <el-input-number
            v-model="ruleForm.totalWeight"
            :precision="3"
            :controls="false"
            placeholder="输入货物明细自动计算"
            disabled
          />
        </el-form-item>
        <el-form-item label="本次提货总价(元)">
          <el-input-number
            v-model="ruleForm.totalGoodsPrice"
            :precision="2"
            :controls="false"
            placeholder="输入货物明细自动计算"
            disabled
          />
        </el-form-item>
        <el-form-item label="本次支付货款金额(元)">
          <el-input-number
            v-model="ruleForm.loanSum"
            :precision="2"
            :controls="false"
            placeholder="输入货物明细自动计算"
            disabled
          />
        </el-form-item>
        <el-form-item label="本次代采费用(元)">
          <el-input-number
            v-model="ruleForm.generationCost"
            :precision="2"
            :controls="false"
            placeholder="输入货物明细自动计算"
            disabled
          />
        </el-form-item>
        <el-form-item v-if="detailObj.isOverdue==='1'" label="待支付逾期费(元)">
          <el-input-number
            v-model="detailObj.overdueCost"
            :precision="2"
            :controls="false"
            placeholder="后台获取"
            disabled
          />
        </el-form-item>
        <el-form-item :rules="rules.name" label="司机姓名" prop="driverName">
          <el-input v-model="ruleForm.driverName" />
        </el-form-item>
        <el-form-item :rules="rules.carNo" label="车牌号" prop="carNum">
          <el-input v-model="ruleForm.carNum" />
        </el-form-item>
        <el-form-item :rules="rules.phone" label="手机号" prop="driverPhone">
          <el-input v-model="ruleForm.driverPhone" />
        </el-form-item>
        <el-form-item
          :rules="rules.selectiveType"
          label="结算账户"
          prop="settleBankId"
        >
          {{ ruleForm.settleBankId }}
          <!-- {{ !ruleForm.settleBankName }} -->
          <span
            v-if="ruleForm.settleBankName"
            class="hand"
          >{{ ruleForm.settleBankName }} &nbsp;&nbsp;可用余额&nbsp;&nbsp;  {{
            ruleForm.avlbCashFund
          }}
            <i
              v-if="!detailObj.settleBankId"
              class="el-icon-circle-close"
              @click="changeButton"
            /></span>
          <el-button
            v-else
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="$refs.selectAccount.dialogTableVisible = true"
          />
        </el-form-item>
      </div>
      <el-form-item label="备注" class="spread-over">
        <el-input
          v-model="ruleForm.remark"
          placeholder="请输入备注"
          maxlength="50"
          show-word-limit
          type="textarea"
        />
      </el-form-item>
      <div class="form-operation">
        <el-button size="small" plain class="widen-button" @click="cancel">
          取消
        </el-button>
        <el-button
          size="small"
          :disabled="disabled"
          type="primary"
          :loading="$store.state.loading"
          class="widen-button"
          @click="submitForm"
        >
          提交
        </el-button>
      </div>
    </el-form>
    <SelectAccount
      v-if="settleBankShow"
      ref="selectAccount"
      :money="ruleForm.totalGoodsPrice"
      :settle-bank-id="ruleForm.settleBankId"
      @getAccount="getAccount"
    />
  </div>
</template>

<script>
import Table from '@/components/Table'
import DetailsPage from '@/components/DetailsPage'
import SelectAccount from '@/components/SelectAccount'
import { rules } from '@/unit/matchFormRegex'
import {
  agentPurchaseContractDetail,
  agentPurchaseContractDeliveryApply
} from '@/http/custApi/purchaseContractManage'
import { distanceDays, roundUpToInteger } from '@/unit/index'
import { accountInfoPage, agentPurchaseContractGetDeliveryAgentAmount } from '@/http/custApi/enterpriseInformation'
export default {
  components: {
    Table,
    DetailsPage,
    SelectAccount
  },
  data() {
    return {
      detailObj: {},
      settleBankShow: false,
      rateLadderItemData: [
        { label: '价格下限', prop: 'lowerLimit' },
        { label: '价格上限', prop: 'upperLimit' },
        { label: '基数(元/吨)', prop: 'base' },
        { label: '规则', prop: 'operFormula' }
      ],
      detailsItemArr: [
        { label: '合同编号', value: 'contractNo' },
        { label: '采购计划单号', value: 'planNo' },
        {
          label: '状态',
          value: 'contractStatus',
          child: this.$store.getters.getDictionaryItem('custContractStatus')
        },
        { label: '合作企业', value: 'supplierCmpName' },
        { label: '代采机构', value: 'fundsCmpName' },
        { label: '代采费率', value: 'miningRate' },
        { label: '采购期限', value: 'maxPurchaseDays', rightWords: '天' },
        { label: '货款延期支付天数', value: 'deferredDays', rightWords: '天' },
        { label: '逾期费率', value: 'overdueInterestRate', rightWords: '%' },
        {
          label: '开票方式',
          value: 'invoicingType',
          child: [
            { id: 1, val: '一票制' },
            { id: 2, val: '多票制' }
          ]
        },
        { label: '货款总额', value: 'totalPayment', rightWords: '元' },
        { label: '保证金比例', value: 'depositRate', rightWords: '%' },
        { label: '保证金金额', value: 'depositPayable', rightWords: '元' },
        {
          label: '结算日期',
          value: 'settlementDate',
          rightWords: '号',
          leftWords: '每月'
        },
        { label: '交收仓库', value: 'deliveryWarehouseName' },
        {
          label: '交货方式',
          value: 'deliveryType',
          child: [
            { id: 1, val: '卖家配送' },
            { id: 2, val: '买家自提' },
            { id: 3, val: '直发' }
          ]
        },
        { label: '交货详细地', value: 'deliveryWarehouseAddr' },
        { label: '代采天数', value: 'purchaseDays', rightWords: '天' },
        { label: '代采费用', value: 'estimateCost', rightWords: '元' },
        // { label: '合同签订日期：（即计息开始日期）', value: 'effectContractDate' },
        { label: '合同到期日', value: 'expiryContractDate' },
        {
          label: '合同附件',
          value: 'contractFileName',
          link: 'contractFileUrl'
        }
      ],
      ruleForm: {
        settleBankId: '',
        goodsReqList: []
      },
      disabled: false,
      rules,
      itemData: [
        { width: '140px', label: '品名', prop: 'goodsName' },
        { width: '140px', label: '材质', prop: 'goodsMaterials' },
        { width: '140px', label: '规格', prop: 'goodsSpecs' },
        { width: '140px', label: '产地', prop: 'goodsProduct' },
        { width: '140px', label: '件重(吨)', prop: 'goodsWeights' },
        // { width: '140px', label: '采购数量', prop: 'purchaseQuantity' },
        // { width: '140px', label: '入库数量', prop: 'invAmount' },
        // { width: '140px', label: '已提数量', prop: 'deliveryAmount' },
        // { width: '140px', label: '已申请数量', prop: 'deliveryFrzAmount' },
        // { width: '140px', label: '可提数量', prop: 'avlbAmount' },
        // { width: '140px', label: '采购数量(吨)', prop: 'purchaseWeight' },
        { width: '140px', label: '入库数量(吨)', prop: 'invWeight' },
        { width: '140px', label: '已提数量(吨)', prop: 'deliveryWeight' },
        { width: '140px', label: '已申请数量(吨)', prop: 'deliveryFrzWeight' },
        {
          width: '200px',
          label: '申请数量(吨)',
          prop: 'avlbWeight',
          fixed: true
        },
        { width: '140px', label: '采购单价(元/吨)', prop: 'purchasePrice' },
        { width: '140px', label: '本次提货合计(元)', prop: 'totalDelivery' }
        // { width: '140px', label: '采购合计(元)', prop: 'totalPrice' }
      ],
      submitObj: {},
      loading: false
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getDetails()
    }
  },
  methods: {
    // 获取支付银行
    getAccount(row) {
      if (!this.submitObj.settleBankId) {
        this.ruleForm.settleBankId = row.settleBankId
        this.ruleForm.settleBankName = row.settleBankName
        this.ruleForm.avlbCashFund = row.avlbCashFund
        this.$forceUpdate()
        this.$refs.form.validateField('settleBankId')
      }
      agentPurchaseContractDeliveryApply(this.submitObj, (res) => {
        if (res.data.length > 0) window.open(res.data, '_blank')
        this.$message.success('申请成功！')
        this.$router.push({
          path: '/purchaseContractManage/index'
        })
        this.$refs.selectAccount.dialogTableVisible = false
      })
    },
    // 点击叉叉的时候
    changeButton() {
      this.ruleForm.settleBankId = ''
      this.ruleForm.settleBankName = ''
      this.ruleForm.avlbCashFund = ''
      this.$forceUpdate()
    },
    // 输入框联动输入
    async enterNumberWeight(row) {
      // 三个三元计算他的
      const weight =
        Number(row.avlbWeight) > Number(row.deliveryTotalWeight2)
          ? Number(row.deliveryTotalWeight2) < 0
            ? ''
            : row.deliveryTotalWeight2
          : Number(row.avlbWeight) < 0
            ? ''
            : row.avlbWeight
      this.$set(row, 'avlbWeight', Number(weight).toFixed(3))
      if (Number(row.deliveryTotalWeight2) < 0) {
        this.$message.error('可提数量小于等于0')
        return
      }
      row.deliveryTotalPayment =
        Number(row.avlbWeight) * Number(row.purchasePrice)
      // 算提货总数量  提货总价  货款总价 等等哪些费用
      this.ruleForm.totalWeight = 0
      this.ruleForm.totalGoodsPrice = 0
      this.ruleForm.loanSum = 0
      // 代采费用从接口获取
      // this.ruleForm.generationCost = 0
      this.ruleForm.expectedCost = 0
      this.ruleForm.settleTotalWeight = 0
      // 获取代采费用 -- 开始
      const arrGoodsReqList = this.ruleForm.goodsReqList.filter((item) => {
        item.deliveryTotalWeight = item.avlbWeight
        return Number(item.avlbWeight) > 0
      })
      this.submitObj = {
        goodsReqList: [...arrGoodsReqList],
        custContractId: this.detailObj.id,
        carNum: this.ruleForm.carNum,
        driverName: this.ruleForm.driverName,
        driverPhone: this.ruleForm.driverPhone,
        remark: this.ruleForm.remark,
        settleBankId: this.ruleForm.settleBankId
      }
      const res = await agentPurchaseContractGetDeliveryAgentAmount(this.submitObj)
      this.ruleForm.generationCost = res.data
      // 获取代采费用 -- 结束
      const arr = []
      // 当提货全部提了的时候  如果在范围内就是最后一次提货  应该减去保证金
      let falg = true
      this.ruleForm.goodsReqList.forEach((item) => {
        this.$set(
          item,
          'totalDelivery',
          roundUpToInteger(
            (Number(item.avlbWeight) || 0) * (Number(item.purchasePrice) || 0)
          )
        )
        if (
          Number(item.floorWeight) > 0 &&
          Number(item.floorWeight) > Number(item.avlbWeight || 0)
        ) { falg = false }
        this.ruleForm.totalWeight += Number(item.avlbWeight) || 0
        // 先判断是不是为负数   负数直接去0
        const settleWeight =
          (Number(item.settledWeight) || 0) - Number(item.deliveryWeight)
          // 计算单条金额
        const singleAmount = ((Number(item.avlbWeight) || 0) -
            (settleWeight > 0 ? settleWeight : 0)) *
          (Number(item.purchasePrice) || 0)
        this.ruleForm.loanSum += singleAmount > 0 ? singleAmount : 0
        this.ruleForm.settleTotalWeight +=
          (Number(item.avlbWeight) || 0) -
          (settleWeight > 0 ? settleWeight : 0)
        if (
          this.detailObj.rateType === 2 &&
          this.detailObj.ladderConfigList &&
          this.detailObj.ladderConfigList.length > 0
        ) {
          this.detailObj.ladderConfigList.forEach((val, index) => {
            // 挡在区间之中时  就去区间中的base  当不是时就取相应的
            // 当值超过最后一个的时候
            if (
              Number(item.purchasePrice) >= Number(val.lowerLimit) &&
              index === this.detailObj.ladderConfigList.length - 1
            ) {
              arr.push({ base: val.base, weight: item.avlbWeight })
              return
            }
            // 当值小于最后一个的时候  而且是第一个的时候
            if (
              Number(item.purchasePrice) <= Number(val.upperLimit) &&
              index === 0
            ) {
              arr.push({ base: val.base, weight: item.avlbWeight })
              return
            }
            if (
              Number(item.purchasePrice) >= Number(val.lowerLimit) &&
              Number(item.purchasePrice) <= Number(val.upperLimit)
            ) {
              arr.push({ base: val.base, weight: item.avlbWeight })
            }
          })
          this.$forceUpdate()
        }
      })
      // 按元每吨计算代采费率   元每吨的时候要把价钱算成提货总价  所以不能减去保证金
      // if (
      //   this.detailObj.rateConfig &&
      //   this.detailObj.rateConfig.rateUnit === 2 &&
      //   this.detailObj.advancePayInfoList
      // ) {
      //   // this.ruleForm.generationCost = roundUpToInteger(this.accordingTonsBilling(this.detailObj.advancePayInfoList, this.ruleForm.loanSum, falg))
      //   this.ruleForm.generationCost = roundUpToInteger(
      //     this.accordingTonsBilling2(
      //       this.detailObj.advancePayInfoList,
      //       this.ruleForm.settleTotalWeight
      //     )
      //   )
      // }
      this.ruleForm.loanSum = roundUpToInteger(
        this.ruleForm.loanSum -
          (this.detailObj.depositAvalFlag === 1 && falg
            ? Number(this.detailObj.depositPaid)
            : 0)
      ) // 如果是最后一次支付  金额就减去保证金
      // 这里是阶梯计费的
      // 当金额小于0的时候   就把金额变为0   意味着不要付钱了
      if (this.ruleForm.loanSum < 0) this.ruleForm.loanSum = 0
      // if (arr.length > 0) {
      //   this.ruleForm.generationCost = 0
      //   arr.forEach((item) => {
      //     this.ruleForm.generationCost +=
      //       Number(item.base) * Number(item.weight)
      //   })
      // }

      // 预计采购费用
      // 计算年月日分别不同的计算
      // if (
      //   this.detailObj.rateConfig &&
      //   this.detailObj.rateConfig.rateUnit === 1 &&
      //   this.detailObj.advancePayInfoList
      // ) {
      //   this.ruleForm.generationCost = roundUpToInteger(
      //     this.accordingRateCalculate(
      //       this.detailObj.advancePayInfoList,
      //       this.ruleForm.loanSum
      //     )
      //   )
      // }
      // 如果是最后一次   而且支付的保证金大于货款金额   代采费用就为0
      // if (
      //   this.detailObj.completionStatus === 1 &&
      //   falg &&
      //   Number(this.ruleForm.loanSum) <= 0 &&
      //   this.detailObj.rateConfig &&
      //   this.detailObj.rateConfig.rateUnit === 1
      // ) {
      //   this.ruleForm.generationCost = 0
      // }
      // 计算逾期费用   是状态已逾期的  而且是天数超过限定日期的
      if (
        this.detailObj.completionStatus === 15 &&
        Number(this.detailObj.expectDays) > Number(this.detailObj.deferredDays)
      ) {
        this.ruleForm.expectedCost = roundUpToInteger(
          (Number(this.detailObj.expectDays) *
            (this.ruleForm.loanSum || 0) *
            Number(this.ruleForm.overdueInterestRate)) /
            100
        )
      }
      this.ruleForm.totalGoodsPrice =
        Number(this.ruleForm.loanSum) + Number(this.ruleForm.generationCost) + (this.detailObj.isOverdue === '1' ? Number(this.detailObj.overdueCost || 0) : 0)
      this.$forceUpdate()
    },
    // 按元/吨计费的计算代采费用
    accordingTonsBilling(arr, money, flag) {
      if (Number(money) <= 0) return 0
      let amountNumber = 0
      let flag1 = false
      let sumo =
        this.detailObj.depositAvalFlag === 1 && flag
          ? Number(money) - Number(this.detailObj.depositPaid)
          : Number(money)
      // 如果提货金额大于当前比较的未还金额，当前吨数 = 未还金额/提货金额  * 提货数量   （得到数据后向上取整），代采费用 = 当前吨位*天数*费率，最后吨位 = 总吨位 - 已分配的吨位之和
      arr.forEach((item, index) => {
        // 计算多少天  多少周
        // 计算当前的吨数  是根据当前吨数 = 未还金额/提货金额  * 提货数量   （得到数据后向上取整）？
        let num = 1
        switch (this.detailObj.rateConfig.rateCycle) {
          case 1:
            num = Number(distanceDays(item.payDate))
            break
          case 2:
            num = Math.ceil(Number(distanceDays(item.payDate)) / 7)
            break
          case 3:
            num = Math.ceil(Number(distanceDays(item.payDate)) / 30)
            break
          case 4:
            num = Math.ceil(Number(distanceDays(item.payDate)) / 360)
            break
        }
        // 如果所提的综合小于第一个的未付金额  吨数就按所填的第一个进行计算
        // if (Number(arr[0].unrepayAmount) >= Number(money)) {
        //   amountNumber = Number(this.detailObj.rateConfig.rateValue) * Number(this.ruleForm.totalWeight) * num
        //   return
        // }
        const weight =
          Math.ceil(
            ((Number(item.unrepayAmount) || 0) / Number(money)) *
              this.ruleForm.totalWeight
          ) || 0
        // 当减去的金额小于或者等于0时  就不在计算代采费用了
        if (sumo <= 0 || flag1) return
        // 当金额小于预付金额的时候  就要把金额全部算起代采费用  后面的就不要算了
        if (sumo > 0 && Number(sumo) <= Number(item.unrepayAmount)) {
          flag1 = true
          const weight3 =
            Math.ceil(
              ((Number(sumo) || 0) / Number(money)) * this.ruleForm.totalWeight
            ) || 0
          amountNumber +=
            Number(this.detailObj.rateConfig.rateValue) * weight3 * num
          return
        }
        // 当最后一次计算的时候   把所有的金额都算入了
        if (sumo > 0 && index === arr.length - 1) {
          const weight2 =
            Math.ceil(
              ((Number(sumo) || 0) / Number(money)) * this.ruleForm.totalWeight
            ) || 0
          amountNumber +=
            Number(this.detailObj.rateConfig.rateValue) * weight2 * num
          return
        }
        amountNumber +=
          Number(this.detailObj.rateConfig.rateValue) * weight * num
        sumo -= Number(item.unrepayAmount)
      })
      return amountNumber
    },
    accordingTonsBilling2(arr, weight) {
      if (Number(weight) <= 0) return 0
      let amountNumber = 0
      // 如果提货金额大于当前比较的未还金额，当前吨数 = 未还金额/提货金额  * 提货数量   （得到数据后向上取整），代采费用 = 当前吨位*天数*费率，最后吨位 = 总吨位 - 已分配的吨位之和
      arr.forEach((item, index) => {
        // 计算多少天  多少周
        // 计算当前的吨数  是根据当前吨数 = 未还金额/提货金额  * 提货数量   （得到数据后向上取整）？
        let num = 1
        switch (this.detailObj.rateConfig.rateCycle) {
          case 1:
            num = Number(distanceDays(item.payDate))
            break
          case 2:
            num = Math.ceil(Number(distanceDays(item.payDate)) / 7)
            break
          case 3:
            num = Math.ceil(Number(distanceDays(item.payDate)) / 30)
            break
          case 4:
            num = Math.ceil(Number(distanceDays(item.payDate)) / 360)
            break
        }
        if (index === 0) {
          amountNumber +=
            Number(this.detailObj.rateConfig.rateValue) * weight * num
        }
      })
      return amountNumber
    },
    // 按计算费率计算价钱
    accordingRateCalculate(arr, money) {
      if (Number(money) <= 0) return 0
      let amountNumber = 0
      let sumo = Number(money)
      let flag = false
      arr.forEach((item, index) => {
        // 计算多少天  多少周
        let num = 1
        switch (this.detailObj.rateConfig.rateCycle) {
          case 1:
            num =
              Number(distanceDays(item.payDate)) < 7
                ? 7
                : Number(distanceDays(item.payDate))
            break
          case 2:
            num =
              (Number(distanceDays(item.payDate)) < 7
                ? 7
                : Number(distanceDays(item.payDate))) / 7
            break
          case 3:
            num =
              (Number(distanceDays(item.payDate)) < 7
                ? 7
                : Number(distanceDays(item.payDate))) / 30
            break
          case 4:
            num =
              (Number(distanceDays(item.payDate)) < 7
                ? 7
                : Number(distanceDays(item.payDate))) / 360
            break
        }
        // 当减去的金额小于或者等于0时  就不在计算代采费用了
        if (sumo <= 0 || flag) return
        // 当最后一次计算的时候  就把所有的钱算到最后一次的里面
        if (sumo > 0 && index === arr.length - 1) {
          amountNumber +=
            ((Number(sumo) || 0) *
              (Number(this.detailObj.rateConfig.rateValue) || 0) *
              num) /
            100
          return
        }

        // 当金额小于预付金额的时候  就要把金额全部算起代采费用  后面的就不要算了
        if (sumo > 0 && Number(sumo) <= Number(item.unrepayAmount)) {
          flag = true
          amountNumber +=
            ((Number(sumo) || 0) *
              (Number(this.detailObj.rateConfig.rateValue) || 0) *
              num) /
            100
          return
        }
        // 如果是正常计算就正常的添加代采费用
        amountNumber +=
          ((Number(item.unrepayAmount) || 0) *
            (Number(this.detailObj.rateConfig.rateValue) || 0) *
            num) /
          100
        sumo -= Number(item.unrepayAmount)
      })
      return amountNumber
    },
    enterNumberAmount(row) {
      row.avlbAmount =
        Number(row.avlbAmount) > Number(row.deliveryTotalQuantity)
          ? Number(row.deliveryTotalQuantity)
          : Number(row.avlbAmount) || 0
      // 算提货总数量
      this.ruleForm.totalQuantity = 0
      this.ruleForm.goodsReqList.forEach((item) => {
        this.ruleForm.totalQuantity += Number(item.avlbAmount) || 0
      })
      this.$forceUpdate()
    },
    // 确定提交
    async submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const arr = this.ruleForm.goodsReqList.filter((item) => {
            item.deliveryTotalWeight = item.avlbWeight
            return Number(item.avlbWeight) > 0
          })
          if (arr.length === 0) {
            this.$message.error('请最少填写一列的可提数量')
            return
          }
          this.submitObj = {
            goodsReqList: [...arr],
            custContractId: this.detailObj.id,
            carNum: this.ruleForm.carNum,
            driverName: this.ruleForm.driverName,
            driverPhone: this.ruleForm.driverPhone,
            remark: this.ruleForm.remark,
            settleBankId: this.ruleForm.settleBankId
          }
          // if (Number(this.ruleForm.avlbCashFund) < Number(this.ruleForm.totalGoodsPrice)) {
          //   this.$message.error(`您的提货总价超过您在${this.ruleForm.settleBankName}的可用余额！`)
          //   return
          // }
          this.settleBankShow = true
          this.$nextTick(() => {
            this.$refs.selectAccount.dialogTableVisible = true
          })
          // this.$confirm(
          //   `本次提货总价${this.ruleForm.totalGoodsPrice}元!`,
          //   '提示',
          //   { type: 'success' }
          // ).then(() => {
          //   agentPurchaseContractDeliveryApply(submitObj, (res) => {
          //     if (res.data.length > 0) window.open(res.data, '_blank')
          //     this.$message.success('申请成功！')
          //     this.$router.go(-1)
          //   })
          // }).catch(() => {})
        }
      })
    },
    // 获取详情
    getDetails() {
      agentPurchaseContractDetail(this.$route.query.id, (res) => {
        this.detailObj = { ...res.data }
        if (this.detailObj.rateConfig) {
          this.detailObj.miningRate = ''
          // 如果是有以前的银行卡  就不要显示了
          if (this.detailObj.settleBankId) {
            // 从接口上获取以前的
            accountInfoPage(
              {
                pageSize: 10,
                pageNum: 1,
                settleBankId: this.detailObj.settleBankId
              },
              (res) => {
                res.data.pageData.forEach((item) => {
                  if (item.settleBankId === this.detailObj.settleBankId) {
                    this.ruleForm.settleBankId = item.settleBankId
                    this.ruleForm.settleBankName = item.settleBankName
                    this.ruleForm.avlbCashFund = item.avlbCashFund
                  }
                })
              }
            )
          }
          // 判断要不要显示选择账户的弹出框
          this.settleBankShow = !this.detailObj.settleBankId
          switch (res.data.rateConfig.rateUnit) {
            case 1:
              this.detailObj.miningRate = res.data.rateConfig.rateValue + '%'
              break
            case 2:
              this.detailObj.miningRate =
                res.data.rateConfig.rateValue + '元/吨'
              break
            case 3:
              this.detailObj.miningRate =
                res.data.rateConfig.rateValue + '元/吨'
              break
            default:
              break
          }
          switch (res.data.rateConfig.rateCycle) {
            case 1:
              this.detailObj.miningRate += '天'
              break
            case 2:
              this.detailObj.miningRate += '周'
              break
            case 3:
              this.detailObj.miningRate += '月'
              break
            case 4:
              this.detailObj.miningRate += '年'
              break
            default:
              break
          }
        }
        this.ruleForm.goodsReqList = (res.data.inventoryGoodsDetails || []).map(
          (item) => {
            // 计算可提的数量
            item.deliveryTotalQuantity =
              Number(item.invAmount || 0) -
              Number(item.deliveryAmount || 0) -
              Number(item.deliveryFrzAmount || 0)
            item.deliveryTotalWeight2 =
              Number(item.invWeight || 0) -
              Number(item.deliveryFrzWeight || 0) -
              Number(item.deliveryWeight || 0)
            item.floorWeight = item.deliveryTotalWeight2 * 1 // 加一个下限值  当提货全部提了的时候  如果在范围内就是最后一次提货  应该减去保证金
            item.deliveryTotalPayment = roundUpToInteger(
              Number(item.deliveryTotalQuantity) *
                Number(item.deliveryTotalWeight2)
            )
            item.contractGoodsDetailId = item.id
            return { ...item }
          }
        )
        // 计算相隔多少天
        this.ruleForm.days = distanceDays(this.detailObj.effectContractDate)
        this.ruleForm.expectDays = distanceDays(
          this.detailObj.expiryContractDate
        )
      })
    },
    // 返回
    cancel() {
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-form {
  .hand {
    i {
      font-size: 18px;
      margin-left: 6px;
      cursor: pointer;
    }
  }
  .tip {
    color: $assistColor5;
    font-size: 14px;
    float: right;
  }
}

.page-form .spread-over {
  width: 100%;
}

.purchase-goods {
  width: 100%;
  margin-bottom: 20px;
  .tableConponents {
    padding: 0 20px;
  }
}

.agreementName {
  color: blue;
  text-decoration: underline;
}
</style>
